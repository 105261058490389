// js
require("dropdowns")
require("bootstrap")
require("prismjs")
require("lottie-web")

// stylesheets
require("../stylesheets/landkit.scss")
require("../stylesheets/feather.css")
require("prismjs/themes/prism-tomorrow")
